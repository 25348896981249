import React, { useCallback } from "react";
import { createPortal } from "react-dom";
import cx from "classnames";
import NiceModal from "@ebay/nice-modal-react";

import { ProjectOrFolderDownloadModal } from "components/_Modals/ProjectDownloadModal/ProjectOrFolderDownloadModal";
import { ActiveProgressBar } from "providers/SharedWorkerProvider/ProgressBarPortal/ActiveProgressBar";
import { FailedProgressBar } from "providers/SharedWorkerProvider/ProgressBarPortal/FailedProgressBar";
import { SucceedProgressBar } from "providers/SharedWorkerProvider/ProgressBarPortal/SucceedProgressBar";
import {
  pollingRequestStatuses,
  useSharedWorker,
} from "../SharedWorkerContext";
import styles from "./ProgressBar.module.scss";

export const ProgressBarPortal = ({
  index,
  percentage,
  nodeId,
  modalParams,
  requestId,
  interval,
  status,
}) => {
  const contentWrapper = document.getElementById("contentWrapper");

  const { activeModal, terminateRequest } = useSharedWorker();
  const openDownloadModal = useCallback(() => {
    NiceModal.show(ProjectOrFolderDownloadModal, {
      ...modalParams,
    });
  }, [modalParams]);

  const handleTerminateRequest = useCallback(
    (e) => {
      e.stopPropagation();
      terminateRequest({ nodeId, requestId, interval });
    },
    [interval, nodeId, requestId, terminateRequest],
  );

  if (activeModal === nodeId || !contentWrapper) {
    return null;
  }

  return createPortal(
    <div
      className={cx(
        "position-fixed shadow-lg rounded-2 cursor-pointer",
        styles.progressBarWrapper,
        {
          "bg-white": status === pollingRequestStatuses.inProgress,
          [styles.progressBarWrapperDone]:
            status === pollingRequestStatuses.done,
          [styles.progressBarWrapperError]:
            status === pollingRequestStatuses.error,
        },
      )}
      style={{
        "--bottom": `${80 + 68 * index}px`,
      }}
      onClick={openDownloadModal}
    >
      {status === pollingRequestStatuses.error && (
        <FailedProgressBar
          handleTerminateRequest={handleTerminateRequest}
          requestId={requestId}
        />
      )}
      {status === pollingRequestStatuses.inProgress && (
        <ActiveProgressBar
          handleTerminateRequest={handleTerminateRequest}
          percentage={percentage}
        />
      )}
      {status === pollingRequestStatuses.done && <SucceedProgressBar />}
    </div>,
    contentWrapper,
  );
};
