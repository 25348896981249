import React from "react";

import { ProgressBarPortal } from "./ProgressBarPortal/ProgressBarPortal";

export const ProgressBars = ({ messages }) => (
  <>
    {[...messages].map(([nodeId, rest], key) => (
      <ProgressBarPortal key={key} index={key} nodeId={nodeId} {...rest} />
    ))}
  </>
);
