import { Button, Icon } from "components";
import styles from "providers/SharedWorkerProvider/ProgressBarPortal/ProgressBar.module.scss";
import React, { useCallback } from "react";
import { useUrqlContext } from "providers";
import { useRequestsToLocalStorageManager } from "hooks/useRequestsToLocalStorageManager";
import { useSharedWorker } from "providers/SharedWorkerProvider/SharedWorkerContext";
import { translate } from "utils";

export const FailedProgressBar = ({ handleTerminateRequest, requestId }) => {
  const { startPolling } = useSharedWorker();

  const { client } = useUrqlContext();
  const { getRequests, removeRequest } = useRequestsToLocalStorageManager();

  const handleRetryRequest = useCallback(
    (e) => {
      e.stopPropagation();
      const requests = getRequests();
      const initialRequest = requests && requests[requestId];
      if (initialRequest.modalParams?.initialOperation) {
        client
          .executeMutation({
            query: initialRequest.modalParams?.initialOperation.query,
            variables: initialRequest.modalParams?.initialOperation.variables,
          })
          .then((result) => {
            if (result?.data?.createTenderText?.requestId) {
              removeRequest(requestId);
              startPolling(
                initialRequest.nodeId,
                result?.data?.createTenderText.requestId,
                initialRequest.modalParams,
              );
            }
          });
      }
    },
    [client, getRequests, removeRequest, requestId, startPolling],
  );
  return (
    <div className="w-100 h-100 d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <Icon
          className="text-danger icon-20 mx-3"
          iconName="circle-info-regular"
          prefix="trox"
        />
        {translate("GENERATION_FAILED")}
      </div>
      <div className="d-flex align-items-center">
        <Button
          size="sm"
          color="transparent"
          icon="arrow-rotate-left-regular"
          iconPrefix="trox"
          iconClassName="icon-16 p-0"
          className={styles.retryButton}
          onClick={handleRetryRequest}
        />
        <button className="btn-close me-2_5" onClick={handleTerminateRequest} />
      </div>
    </div>
  );
};
