import React from "react";
import cx from "classnames";
import { Icon } from "components";

import { translate } from "utils";
import styles from "./ProgressBar.module.scss";

export const SucceedProgressBar = () => (
  <div className="w-100 h-100 d-flex align-items-center justify-content-between">
    <div className="d-flex align-items-center">
      <Icon
        className={cx("icon-20 mx-3", styles.successBar)}
        iconName="alert-icon"
        prefix="trox"
      />
      {translate("GENERATION_SUCCEEDED")}
    </div>
  </div>
);
