import { Progress } from "reactstrap";
import cx from "classnames";
import React from "react";
import styles from "providers/SharedWorkerProvider/ProgressBarPortal/ProgressBar.module.scss";
import { translate } from "utils";

export const ActiveProgressBar = ({ handleTerminateRequest, percentage }) => (
  <>
    <div className="w-100 p-2_5 pb-2 d-flex align-items-center justify-content-between">
      <div>{translate("GENERATION_IN_PROGRESS")}</div>
      <button className="btn-close" onClick={handleTerminateRequest} />
    </div>
    <Progress
      value={percentage || 2}
      className={cx("rounded-3", styles.progressBar)}
      color="info"
    />
  </>
);
