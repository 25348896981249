import { useCallback } from "react";

export const useRequestsToLocalStorageManager = () => {
  const getRequests = useCallback(() => {
    try {
      return JSON.parse(localStorage.getItem("activeRequests")) || {};
    } catch {
      return {};
    }
  }, []);

  const saveRequest = useCallback(
    (requestId, data) => {
      const requests = getRequests();
      requests[requestId] = data;
      localStorage.setItem("activeRequests", JSON.stringify(requests));
    },
    [getRequests],
  );

  const removeRequest = useCallback(
    (requestId) => {
      const requests = getRequests();
      delete requests[requestId];
      localStorage.setItem("activeRequests", JSON.stringify(requests));
    },
    [getRequests],
  );

  return { getRequests, saveRequest, removeRequest };
};
