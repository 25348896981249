import React, { useCallback, useEffect, useRef, useState } from "react";
import { useUrqlContext } from "providers/UrqlProvider";
import { useRequestsToLocalStorageManager } from "hooks/useRequestsToLocalStorageManager";
import { downloadFileByURL } from "utils/downloadFileByURL";
import { GetReportQry } from "api/queries";
import { authIndicatorFlag } from "api/auth";
import { ProgressBars } from "./ProgressBars";
import {
  pollingRequestStatuses,
  SharedWorkerContext,
} from "./SharedWorkerContext";

export const SharedWorkerProvider = ({ children }) => {
  const [messages, setMessages] = useState(new Map());
  const [activeModal, setActiveModal] = useState(null);
  const workerRef = useRef(null);
  const { client } = useUrqlContext();
  const { getRequests, saveRequest, removeRequest } =
    useRequestsToLocalStorageManager();

  const sendMessage = useCallback((message) => {
    if (workerRef.current) {
      workerRef.current.port.postMessage(message);
    }
  }, []);

  const startPolling = useCallback(
    (qryNodeId, qryRequestId, modalParams) => {
      const interval = setInterval(() => {
        client
          .query(
            GetReportQry,
            { requestId: qryRequestId },
            { requestPolicy: "network-only" },
          )
          .then(({ data, error }) => {
            if (error) {
              clearInterval(interval);
              sendMessage({
                type: "update",
                nodeId: qryNodeId,
                status: pollingRequestStatuses.error,
              });
              return;
            }
            if (data?.getReport?.status === pollingRequestStatuses.inProgress) {
              sendMessage({
                type: "update",
                requestId: qryRequestId,
                nodeId: qryNodeId,
              });
            }
            if (data?.getReport?.status === pollingRequestStatuses.error) {
              clearInterval(interval);
              sendMessage({
                type: "update",
                requestId: qryRequestId,
                nodeId: qryNodeId,
                status: pollingRequestStatuses.error,
              });
            }
            if (data?.getReport?.status === pollingRequestStatuses.done) {
              sendMessage({
                type: "update",
                requestId: qryRequestId,
                status: pollingRequestStatuses.done,
                nodeId: qryNodeId,
              });
              clearInterval(interval);
              removeRequest(qryRequestId);
              downloadFileByURL(data?.getReport?.url);

              setTimeout(() => {
                sendMessage({
                  type: "remove",
                  nodeId: qryNodeId,
                });
              }, 5000);
            }
          });
      }, 5000);

      sendMessage({
        type: "update",
        requestId: qryRequestId,
        nodeId: qryNodeId,
        status: pollingRequestStatuses.inProgress,
        modalParams,
        interval,
      });

      saveRequest(qryRequestId, {
        requestId: qryRequestId,
        nodeId: qryNodeId,
        status: pollingRequestStatuses.inProgress,
        modalParams,
      });
    },
    [client, removeRequest, saveRequest, sendMessage],
  );

  useEffect(() => {
    if (!window.SharedWorker) {
      console.error("SharedWorker isn't supported");
      return;
    }

    if (!workerRef.current) {
      try {
        workerRef.current = new SharedWorker("worker.js");
      } catch (error) {
        console.error("SharedWorker wasn't created", error);
        return;
      }
      workerRef.current.port.onmessage = (event) => {
        const { data } = event;

        if (data.type === "stateRemove") {
          setMessages((prevState) => {
            const newMessages = new Map(prevState);
            newMessages.delete(data.data.nodeId);
            return newMessages;
          });
          return;
        }

        if (data.type === "fullState") {
          if (data.data.length === 0) {
            const localStorageRequestsArray = Object.entries(getRequests());

            if (
              localStorageRequestsArray.length > 0 &&
              localStorage.getItem(authIndicatorFlag)
            ) {
              localStorageRequestsArray.forEach(([requestId, requestData]) => {
                startPolling(
                  requestData.nodeId,
                  requestId,
                  requestData.modalParams,
                );
              });
            }
          }
          setMessages(new Map(data.data));
          return;
        }

        if (data?.data?.nodeId && data?.data?.percentage !== undefined) {
          setMessages((prevState) => {
            const newMessages = new Map(prevState);
            const {
              nodeId,
              status,
              percentage,
              requestId,
              modalParams,
              interval,
            } = data.data;
            const existingMessage = newMessages.get(nodeId);

            const isSameState =
              existingMessage?.status === status &&
              existingMessage?.percentage === percentage;

            if (isSameState) {
              return prevState;
            }

            newMessages.set(nodeId, {
              status: status || existingMessage?.status,
              percentage,
              requestId: requestId || existingMessage?.requestId,
              modalParams: modalParams || existingMessage?.modalParams,
              interval: interval || existingMessage?.interval,
            });

            return newMessages;
          });
          return;
        }
        console.error("Invalid message from SharedWorker:", data);
      };

      workerRef.current.port.postMessage({ type: "getState" });

      workerRef.current.port.start();

      workerRef.current.onerror = (error) => {
        console.error("Error SharedWorker:", error);
      };
    }

    return () => {
      if (workerRef.current) {
        workerRef.current.port.close();
      }
    };
  }, [getRequests, startPolling]);

  const terminateRequest = useCallback(
    ({ interval, nodeId, requestId }) => {
      clearInterval(interval);
      removeRequest(requestId);
      sendMessage({
        type: "remove",
        nodeId,
      });
    },
    [removeRequest, sendMessage],
  );

  const getMessageByNodeId = useCallback(
    (nodeId) => messages.get(nodeId),
    [messages],
  );

  return (
    <SharedWorkerContext.Provider
      value={{
        messages,
        activeModal,
        setActiveModal,
        getMessageByNodeId,
        startPolling,
        terminateRequest,
      }}
    >
      {children}
      {messages.size > 0 && <ProgressBars messages={messages} />}
    </SharedWorkerContext.Provider>
  );
};
